
html {
    overflow-y: scroll;
}

body {
    margin: 0;

}

.pre {
    white-space: pre;
}

// move into components
.app-container {
    display: flex;
    flex-flow: column;
    margin: 1.5rem 2rem 1rem;
    /* To let the sticky footer appear at the correct position, the height needs to be 100%.
     Unfortunately the height of the header (7.125rem) is not taken into account, therefore it is subtracted here. */
    height: calc(100% - 7.125rem);
}

.authenticating-loader {
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    font-size: 5rem;
    background: #FFF;
    opacity: 0.8;
}

.break-all {
    word-break: break-all;
}

.table-props tr th {
    width: 1px;
}

.table-counter-wrapper {
    font-size: 1rem;
    line-height: 1.3em;

    padding: 1rem;
    display: flex;
    justify-content: flex-start;
    column-gap: 4px;
    width: 50%;
}

.search-field {
    width: 50%;
}

.primary-color-text {
    color: var(--color-primary);
    font-weight: 500;
}

.primary-color-icon {
    color: var(--color-primary);
}

.error-message {
    align-items: center;
    color: var(--color-error);
    column-gap: 8px;
    display: flex;
}

.full-height {
    height: 100%;
}

.full-height-block {
    display: block;
    @extend .full-height;
}

portal-layout {
    display: flex;
    flex-flow: column nowrap;
    height: 100%;

    > * {
        flex: 0 0 auto;
    }

    .page-content {
        flex: 1 0 auto;
    }
}

// Todo Edit this in Lib Later
.form-component .form-component-wrapper label {
    color: #9B9B9B;
    font-size: 0.75rem;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
}

.disabled-preview {
    opacity: 0.6;
}

.width-12em {
    width: 12em !important;
}

.uppercase-placeholder::placeholder {
    font-size: 0.875em;
    text-transform: uppercase;
}

.mat-sort-header-arrow {
    color: var(--color-white) !important;
}
