// Todo: Only use fe-lib styles through components not via global styles (or only a defined set, e.g. reset.scss)
@import 'node_modules/@concession-portal/ng-dso-ui-components-fe/src/styles/mixins/typography';

// Todo: refactor and remove
@import 'styles/typography';
@import 'styles/flex';
@import 'styles/globals-to-refactor';

@import 'styles/input';

body {
    font-family: var(--font-family);
    font-weight: normal;
}
