.flex {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    align-items: flex-start;
}

.flex-space {
    flex: 1 1 auto;
}

.w-100 {
    width: 100%;
}

.align-items-center {
    align-items: center;
}

.justify-content-center {
    justify-content: center;
}

.justify-space-around {
    justify-content: space-around;
}

.justify-space-between {
    justify-content: space-between;
}

.justify-end {
    justify-content: flex-end;
}
