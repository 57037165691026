// TODO: KOMPASS-5947 => @Jobst can this code removed?
.container h1 {
    margin: 0 0 0.58em 0;
}

.align-right {
    text-align: right;
}

a {
    color: var(--color-primary);

    text-decoration: underline;

    &:hover,
    &:focus {
        text-decoration: none;
    }
}
